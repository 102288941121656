/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { graphql, Link } from 'gatsby';

// UI lib components
import { Container, Visible, useScreenClass } from 'react-grid-system';

// UI local components
import ReactPlayer from 'react-player';
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/BannerSection';

// Local API
import { understandHorizonList } from '../shared/local-links/underMenuLinks';
import { LINK_API } from '../shared/APIs';

// Assets
import BANNER_IMG from '../images/understand-horizon-page/new-banner.jpg';

// Style
import '../page-styles/understand-horizon.scss';
import UtileLinks from '../helpers/usefull_link';
import '../shared/styles/global.scss';

/* -------------------------------------------------------------------------- */
/*                           Underdtand Horizon Page                          */
/* -------------------------------------------------------------------------- */

function UnderstandHorizonPage() {
  /* ------------------------------- CONST --------------------------------- */
  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm', 'md'].includes(screenClass);

  /* --------------------------------- HOOKS -------------------------------- */

  const [contentSwitcher, setContentSwitcher] = useState('');
  const [UnderstandHorData, setUnderstandHorData] = useState([]);
  const [resources, setResources] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    axios
      .get(
        `${LINK_API}/api/understand-horizons?populate[section][populate]=*&populate[understand-horizon][populate]=`,
      )
      .then(({ data: { data } }) => {
        setUnderstandHorData(
          data[0].attributes.section.map((section) => section),
        );
      })
      .then(() => setLoader(false))
      .catch((error) => error);
  }, []);

  useEffect(() => {
    if (contentSwitcher === 'Ressources juridiques et financières') {
      if (!resources?.length > 0) {
        axios
          .get(
            'https://admin522.horizon-europe.tn/api/understand-horizons?populate=resources.links',
          )
          .then(({ data: { data } }) => {
            setResources(data);
          });
      }
    }
  }, [contentSwitcher, resources?.length]);

  /* --------------------------------- HELPERS ------------------------------ */
  // fromat description:
  function formatDesc(desc) {
    let newDesc = '';
    for (let i = 0; i < desc?.length; i += 1) {
      newDesc += desc.charAt(i);
      const check = desc.charAt(i);
      if (check === '.') {
        newDesc += ' <br><br>';
      }
    }
    return { __html: newDesc };
  }
  // Menu
  const Menu = understandHorizonList.map((link) => {
    return (
      <Container
        key={link.id}
        className={`single-link text-center flex justify-center items-center ${
          link.title === contentSwitcher && 'link-active-understand'
        } fluid`}
        onClick={() => setContentSwitcher(link.title)}
      >
        <li>
          <h5
            className={
              link.title === contentSwitcher && 'link-active-understand'
            }
          >
            {link.title}
          </h5>
        </li>
      </Container>
    );
  });

  function getHorizonFinancialRessources() {
    return resources[0]?.attributes?.resources?.map(
      ({ id, category, links }) => (
        <Container className="top fluid" key={id}>
          <h3>{category}</h3>
          {links.map(({ label, url }) => (
            <Container className="top fluid">
              <UtileLinks title={label} URL={url} />
            </Container>
          ))}
        </Container>
      ),
    );
  }

  function getPageContent() {
    if (contentSwitcher === '') {
      return (
        <Container className="page-content-understand flex-column top fluid">
          <div className="page-content-understand-image">
            <img
              className="understand-img"
              src={`${LINK_API}${UnderstandHorData[0]?.picture.data.attributes.url}`}
              alt=""
            />
          </div>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={formatDesc(
              UnderstandHorData[0]?.description,
            )}
          />
        </Container>
      );
    }
    if (contentSwitcher === 'Présentation du programme') {
      return (
        <>
          <Container className="page-content-understand flex top fluid">
            <div className="page-content-understand-image">
              <img
                className="understand-img"
                src={`${LINK_API}${UnderstandHorData[1]?.picture?.data?.attributes.url}`}
                alt=""
              />
            </div>
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={formatDesc(
                UnderstandHorData[1]?.description,
              )}
            />
            <Container className="fluid top">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=mX-o4vFe-7s&t=8s"
                width={IS_MOBILE ? '100%' : '640px'}
                height={IS_MOBILE ? '200px' : '350px'}
              />
            </Container>
          </Container>
        </>
      );
    }
    return (
      <>
        {getHorizonFinancialRessources()}
        <Container className="top fluid">
          <h3>
            Comment préparer une proposition de projet réussie dans Horizon
            Europe:
          </h3>
          <Container className="top fluid">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=_1wywAOPdW0"
              width={IS_MOBILE ? '100%' : '640px'}
              height={IS_MOBILE ? '200px' : '350px'}
            />
          </Container>
        </Container>
      </>
    );
  }

  function getAllPageContent() {
    if (loader) {
      return '';
    }
    return (
      <Container className="understand-horizon fluid" fluid>
        <Container className="menu-section ">
          <ul className="topMenu flex fluid">{Menu}</ul>
        </Container>

        <Container className="all-content-understand">
          <Visible lg xl xxl>
            <Container className="top flex items-center fluid">
              <Link className="breadcrumb__link" to="/">
                Accueil
              </Link>
              <span className="m-r-l">/</span>
              <Link
                className="breadcrumb__link"
                to="/understand-horizon/"
                onClick={() => setContentSwitcher('')}
              >
                Horizon Europe
              </Link>
            </Container>
          </Visible>
          {getPageContent()}
        </Container>
      </Container>
    );
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout loader={loader}>
      <Seo title="Horizon Europe" />
      <BannerSection bannerImage={BANNER_IMG} title="Horizon Europe" />

      {getAllPageContent()}
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default UnderstandHorizonPage;
